@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.slick-slide > div {
  margin: 0 3px;
}

*:focus {
  outline: 0;
  outline: none;
}

.selected {
  border: 1px solid #0f172a;
  border-radius: 5px;
}